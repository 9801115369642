
body, html::before{
  z-index:-2;
  background-color:black;
}


html::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black url('bbground.jpeg');
  opacity: 0.3; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure it stays behind the content */
}


body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  z-index: 1;
}
