
.Contact-Box{
  width:300px;
  height:150px;
  background-color: rgba(0, 0, 0, 0.499);
  border-radius:15px;
  transform:translate(565px,400px);
}


.Contact-Header{
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-align:center;
font-weight:200;
font-size:22px;
padding-top:5px;
}

.Contact-Info{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align:center;
  font-weight:400;
  padding-top:25px;
  
}

.Contact-Footer{
  padding-top:30px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight:100;
  text-align:center;
}