


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.App {
  text-align: center;
  background-color: #141414; /* Dark background */
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App-header {
  background-color: #000000; /* Slightly darker header */
  padding: 12px 0; /* Padding only top and bottom */
  position: relative; /* Added to position the logo absolutely */
}

.App-logo {
  position: absolute; /* Absolute position */
  top: 50%; /* Center vertically */
  left: 20px; /* Space from the left */
  transform: translateY(-50%); /* Center the logo with transform */
  height: 20px; /* Adjust the height to ensure the logo does not stick out */
  overflow: hidden; /* This hides any overflow */
}



.App-logo img {
  display: block;
  width: auto; /* Adjust if necessary */
  max-width: 100%; /* Ensure it doesn't overflow its container */
  max-height: 100%; /* Ensure it doesn't overflow its container */
  margin: 0; /* Remove any margin */
}


.App-nav {
  display: flex;
  justify-content: center; /* Center the nav */
  width: 100%; /* Take full width to center content */
}

nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 16px;
  padding: 8px 16px;
  font-weight:120;
}

.nav-button{
  color:white;
}

.nav-button:hover,
.nav-button:active{
  color:rgb(194, 194, 194);
}






