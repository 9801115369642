.main-content{
  color:white;
}

.borderBox {
  background-color: rgba(0, 0, 0, 0.669); /* Adjust the opacity as needed */
  padding: 20px; /* Add padding as needed */
  color: white; /* Text color */
  /* Other styling as needed */
  width:1440px;
  height:200px; 
  border-radius:15px;
  transform: translate(0px, 170px);
  
}

.AboutUs-Header{
font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-align:center;
font-size:25px;
font-weight:500;
}

.AboutUs-Text {
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align:center;
  padding-top:20px;
  font-weight:200;
  font-size:20px;
  padding-left: 200px;
  padding-right: 200px;
}