.opaque-box {
  background-color: rgba(0, 0, 0, 0.669); /* Adjust the opacity as needed */
  padding: 20px; /* Add padding as needed */
  color: white; /* Text color */
  /* Other styling as needed */
  width:400px;
  height:450px; 
  border-radius:15px;
  transform: translate(900px, 90px);
  
}

.home-title{
color:rgb(255, 255, 255);
font-size:25px;
font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight:200;
text-align:center;
}

.home-description{
color:rgb(255, 255, 255);
font-size:20px;
font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
padding-top:70px;
padding-bottom:10px;
font-weight:200;
line-height:40px;
}

.learn-more-button {
  display: inline-block;
  background-color: #202020; /* Example button color */
  color: white; /* Text color */
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* Other button styling as needed */
  font-weight:120;
  transform: translate(220px,-20px);
  font-size:25px;
  font-weight:120;
}



.learn-more-button:hover,
.learn-more-button:active {
  background-color: #2d2d2d; /* Darker shade for hover effect */
}
